export const JobList = [
  {
    name: "Manulife",
    id: "manulife",
    color: "#e51b26",
    mainImage: "/imgs/Manulife/Manulife_01.jpg",
    images: [
      "/imgs/Manulife/Manulife_01.jpg",
      "/imgs/Manulife/Manulife_02.jpg",
      "/imgs/Manulife/Manulife_03.jpg",
      "/imgs/Manulife/Manulife_04.jpg",
      "/imgs/Manulife/Manulife_05.jpg",
      "/imgs/Manulife/Manulife_06.jpg",
    ],
  },
  {
    name: "Scotiabank",
    id: "scotiabank",
    color: "#e51b26",
    mainImage: "/imgs/ScotiaBank/ScotiaBank_01.jpg",
    images: [
      "/imgs/ScotiaBank/ScotiaBank_01.jpg",
      "/imgs/ScotiaBank/ScotiaBank_02.jpg",
      "/imgs/ScotiaBank/ScotiaBank_03.jpg",
      "/imgs/ScotiaBank/ScotiaBank_04.jpg",
    ],
  },
  {
    name: "IKO",
    id: "iko",
    color: "#414042",
    mainImage: "/imgs/IKO/IKO_01.jpg",
    images: [
      "/imgs/IKO/IKO_01.jpg",
      "/imgs/IKO/IKO_02.jpg",
      "/imgs/IKO/IKO_03.jpg",
      "/imgs/IKO/IKO_04.jpg",
      "/imgs/IKO/IKO_05.jpg",
      "/imgs/IKO/IKO_06.jpg",
      "/imgs/IKO/IKO_07.jpg",
      "/imgs/IKO/IKO_08.jpg",
    ],
  },

  {
    name: "Lime Adversiting",
    id: "lime-advertising",
    color: "#82C8BD",
    mainImage: "imgs/Lime_Adversiting/LimeAdvertising_01.jpg",
    images: [
      "/imgs/Lime_Adversiting/LimeAdvertising_01.jpg",
      "/imgs/Lime_Adversiting/LimeAdvertising_02.jpg",
      "/imgs/Lime_Adversiting/LimeAdvertising_03.jpg",
      "/imgs/Lime_Adversiting/LimeAdvertising_04.jpg",
      "/imgs/Lime_Adversiting/LimeAdvertising_05.jpg",
      "/imgs/Lime_Adversiting/LimeAdvertising_06.jpg",
      "/imgs/Lime_Adversiting/LimeAdvertising_07.jpg",
    ],
  },

  {
    name: "Staples",
    id: "staples",
    color: "#e51b26",
    mainImage: "imgs/Staples/Staples_01.jpg",
    images: [
      "/imgs/Staples/Staples_01.jpg",
      "/imgs/Staples/Staples_02.jpg",
      "/imgs/Staples/Staples_03.jpg",
      "/imgs/Staples/Staples_04.jpg",
      "/imgs/Staples/Staples_05.jpg",
      "/imgs/Staples/Staples_06.jpg",
      "/imgs/Staples/Staples_07.jpg",
    ],
  },
  {
    name: "Basic Fun",
    id: "basic-fun",
    color: "#97c9ed",
    mainImage: "imgs/BasicFun/BasicFun_01.jpg",
    images: [
      "/imgs/BasicFun/BasicFun_01.jpg",
      "/imgs/BasicFun/BasicFun_02.jpg",
      "/imgs/BasicFun/BasicFun_03.jpg",
      "/imgs/BasicFun/BasicFun_04.jpg",
      "/imgs/BasicFun/BasicFun_05.jpg",
      "/imgs/BasicFun/BasicFun_06.jpg",
      "/imgs/BasicFun/BasicFun_07.jpg",
      "/imgs/BasicFun/BasicFun_08.jpg",
      "/imgs/BasicFun/BasicFun_09.jpg",
    ],
  },
];
