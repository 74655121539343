import React from "react";
import { Link } from "react-router-dom";
import { JobList } from "../data/JobList";

function Portfolio() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <main className="portfolio-page">
      {JobList.map((item) => (
        <Link key={item.id} to={item.id}>
          <div onClick={handleClick} className="image_wrapper">
            <div className="overlay">{item.name}</div>
            <img src={item.mainImage} alt="" srcset="" />
          </div>
        </Link>
      ))}
    </main>
  );
}

export default Portfolio;
