import React from "react";

function Footer() {
  const Year = new Date().getFullYear();
  return (
    <div className="footer full-bleed-bg">
      <a
        href="https://www.linkedin.com/in/rafrahman/"
        target="_blank"
        rel="noopener noreferrer"
      >
        &copy; {Year} Raf Rahman // Linkedin
      </a>
    </div>
  );
}

export default Footer;
