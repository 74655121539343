import React from "react";

function About() {
  const datesOfExperience = new Date().getFullYear() - 2014;
  return (
    <div className="about-page">
      <img className="about-profile" src="imgs/aboutme/ProfilePic.jpg" alt="" />
      <div className="about-text">
        <p>Hello!</p>
        <p>
          My name is Refa-At but everyone calls me Raf. I am an integrated
          designer, experienced with digital marketing, print design, corporate
          branding, and proficient with PDF formatting for AODA compliance.
        </p>
        <p>
          With {datesOfExperience} years of experience in the industry, I've had
          the pleasure of working with a wide range of clients on projects that
          span various mediums.
        </p>
        <p>
          In my off time, I like to further develop my design skills through
          board game design and visual storytelling. These hobbies fuel my
          creativity and often find their way into my design work.
        </p>
      </div>
      <div className="client-list">
        <h1>Clients</h1>
        <ul>
          <li>TD Canada Trust</li>
          <li>Manulife</li>
          <li>Scotiabank</li>
          <li>IKO</li>
          <li>Lime Advertising</li>
          <li>Canadian Tire</li>
          <li>Staples</li>
          <li>Basic Fun</li>
        </ul>
      </div>
      <div className="skill-list">
        <h1>Skills</h1>
        <div className="skill-icons">
          <img className="app-icons" src="imgs/aboutme/icons/cc.png" alt="" />
          <img
            className="app-icons"
            src="imgs/aboutme/icons/photoshop.png"
            alt=""
          />
          <img
            className="app-icons"
            src="imgs/aboutme/icons/illustrator.png"
            alt=""
          />
          <img
            className="app-icons"
            src="imgs/aboutme/icons/indesign.png"
            alt=""
          />
          <img className="app-icons" src="imgs/aboutme/icons/xd.png" alt="" />
          <img
            className="app-icons"
            src="imgs/aboutme/icons/figma.png"
            alt=""
          />
          <img
            className="app-icons"
            src="imgs/aboutme/icons/premiere.png"
            alt=""
          />
          <img
            className="app-icons"
            src="imgs/aboutme/icons/aftereffect.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default About;
