import React from "react";

function Logo() {
  return (
    <div className="logo">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
      >
        <path
          id="OUTLINE"
          className="logo-color"
          d="m250,495.82L4.18,250,250,4.18l245.82,245.82-89.47,89.46,25.95,92.74-92.29-26.4-90.02,90.01ZM23.84,250l226.16,226.16,85.97-85.97,76.25,21.81-21.44-76.61,85.38-85.38L250,23.84,23.84,250Z"
        />
        <path
          id="FIRST_R"
          data-name="FIRST R"
          className="logo-color"
          d="m176.81,351.91l19.12-19.12c3.87-3.95,37.19-40.11.08-77.22-37.11-37.11-73.27-3.79-77.27.13l-37.83,37.83,168.75,168.75,69.53-69.53-142.39-40.84Z"
        />
        <path
          id="LAST_R"
          data-name="LAST R"
          className="logo-color"
          d="m255.7,118.74c-3.92,4-37.24,40.16-.13,77.27,37.11,37.11,73.27,3.79,77.22-.08l19.12-19.12,40.84,142.39,69.53-69.53-168.75-168.75-37.83,37.83Z"
        />
        <path
          id="ARROW_HEAD"
          data-name="ARROW HEAD"
          className="logo-color"
          d="m335.17,233.56c-4.92,2.76-10.61,5.42-16.94,7.53-12.1,4.02-27.67,6.33-44.64,1.8l-30.7,30.7c4.52,16.98,2.22,32.54-1.8,44.64-2.11,6.33-4.77,12.02-7.53,16.94l142.54,41.05-40.92-142.67Z"
        />
        <path
          id="ARROW_NECK"
          data-name="ARROW NECK"
          className="logo-color"
          d="m236.73,257.83l21.1-21.1c-5.72-2.96-11.51-6.83-17.32-11.73l-15.5,15.5c4.9,5.81,8.77,11.61,11.73,17.32Z"
        />
        <path
          id="ARROW_BODY"
          data-name="ARROW BODY"
          className="logo-color"
          d="m229.35,214.22c-27.51-30.26-25.63-60.48-18.86-80.87,7.17-21.58,20.9-35.72,22.44-37.27l37.88-37.88-20.48-20.48L37.72,250.34l20.48,20.48,37.88-37.88c1.54-1.54,15.68-15.27,37.27-22.44,20.4-6.78,50.61-8.65,80.87,18.86l15.13-15.13Z"
        />
      </svg>
      <div className="logo-text">
        <p>Raf Rahman</p>
        <div className="logo-line"></div>
        <p>Graphic Designer</p>
      </div>
    </div>
  );
}

export default Logo;
