import React from "react";
import { useParams, NavLink } from "react-router-dom";
import { JobList } from "../data/JobList";

function PortfolioPage() {
  const { portId } = useParams();
  const jobItem = JobList.find((item) => item.id === portId);
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <main className="JobImageList">
      <h1 className="page-title">{jobItem.name}</h1>
      {jobItem.images.map((image) => (
        <img src={image} alt="" />
      ))}
      <div className="JobCatergory">
        <NavLink onClick={handleClick} to="/">
          All
        </NavLink>
        {JobList.map((category) => (
          <NavLink onClick={handleClick} to={`/portfolio/${category.id}`}>
            / {category.name}
          </NavLink>
        ))}
      </div>
    </main>
  );
}

export default PortfolioPage;
