import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "./Logo";

// Pages
import Home from "../pages/Home";
import About from "../pages/About";
import Portfolio from "../pages/Portfolio";
import Contact from "../pages/Contact";

function Navbar() {
  const [menuToggle, setMenuToggle] = useState(false);
  const [hamburger, setHamburger] = useState("hamburger");

  const menuToggleHandle = () => {
    if (!menuToggle) {
      setHamburger("hamburger-open");

      setMenuToggle(!menuToggle);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setHamburger("hamburger");

      setMenuToggle(!menuToggle);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const menuToggleClose = () => {
    setHamburger("hamburger");

    setMenuToggle(false);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav>
      <Link to="/" element={<Home />} onClick={menuToggleClose}>
        <Logo />
      </Link>
      <div className={hamburger} onClick={menuToggleHandle}>
        <div className="bar"></div>
      </div>

      <div className={menuToggle ? "nav-menu mobile-menu" : "nav-menu"}>
        <ul>
          <li>
            <NavLink
              to="/portfolio"
              element={<Portfolio />}
              onClick={menuToggleClose}
            >
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" element={<About />} onClick={menuToggleClose}>
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              element={<Contact />}
              onClick={menuToggleClose}
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
