import React from "react";

function Contact() {
  return (
    <div className="contact-page">
      <h1>Contact & Info</h1>
      <p>
        Thank you for your interest in my work! If you have any questions or
        comments, feel free to get in touch with me!
      </p>
      <div className="contact-text">
        <a href="mailto:thisisraf@gmail.com">
          <img
            className="contact-icon"
            src="imgs/Assest/email.png"
            alt=""
            srcset=""
          />
        </a>
        <a
          href="https://www.linkedin.com/in/rafrahman/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="contact-icon"
            src="imgs/Assest/linkedin.png"
            alt=""
            srcset=""
          />
        </a>
      </div>
    </div>
  );
}

export default Contact;
