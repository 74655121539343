import React from "react";
import { Link } from "react-router-dom";
import { JobList } from "../data/JobList";

function Home() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <main>
      {JobList.slice(0, 6).map((item) => (
        <Link onClick={handleClick} key={item.id} to={`portfolio/${item.id}`}>
          <div className="image_wrapper">
            <div className="overlay">{item.name}</div>
            <img src={item.mainImage} alt="" srcSet="" />
          </div>
        </Link>
      ))}
    </main>
  );
}

export default Home;
